import FormContainer_ResponsiveSkinComponent from '@wix/thunderbolt-elements/src/components/FormContainer/viewer/skinComps/ResponsiveSkin/ResponsiveSkin.skin';


const FormContainer_ResponsiveSkin = {
  component: FormContainer_ResponsiveSkinComponent
};


export const components = {
  ['FormContainer_ResponsiveSkin']: FormContainer_ResponsiveSkin
};

